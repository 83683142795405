<template lang="pug">
  .competitor-definition
    Transition(name="fade" appear)
      .rental-periods
        .rental-period(
          v-for="period in rentalPeriods"
          :key="period.value"
        )
          .rental-period-head
            .title {{ period.title }}
          .competitor(
            v-for="competitor in competitors"
            :key="competitor.id"
          )
            .competitor-name(
              :class="{ 'no-plans': isEmpty(competitor.price_plans[period.value]), active: competitorToggler(period.value, competitor).state }"
              :title="competitor.shop_name"
              @click="competitorToggler(period.value, competitor).toggle()"
            ) {{ competitor.shop_name }}
            .competitor-plan(
              v-if="competitorToggler(period.value, competitor).state"
              v-for="plan in competitor.price_plans[period.value]"
              :key="`${competitor.id}_${plan.id}_${plan.name}`"
              :title="plan.name"
            ) {{ plan.name }}
</template>

<script>
  import { reduce, forEach, get, isEmpty } from "lodash-es"
  import { toggleize } from "@/helpers/toggler"

  export default {
    props: {
      competitors: {
        type: Array,
        default: () => new Array()
      },
      rentalPeriods: Array
    },

    computed: {
      competitorsTogglers() {
        return reduce(
          this.rentalPeriods,
          (obj, { value: rentalPeriod }) => {
            forEach(this.competitors, competitor => {
              obj[rentalPeriod] ||= {}

              obj[rentalPeriod][competitor.id] = toggleize(`toggler-${rentalPeriod}-${competitor.id}`, false)
            })

            return obj
          },
          {}
        )
      }
    },

    methods: {
      isEmpty,

      competitorToggler(rentalPeriod, competitor) {
        return get(this.competitorsTogglers, `${rentalPeriod}.${competitor.id}`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  $competitor-name-padding-left: 30px
  $competitor-row-height: 50px

  .competitor-definition
    border-right: 1px solid $default-purple-light-line
    box-sizing: content-box
    width: 315px

    .car-class-name
      align-items: center
      box-sizing: content-box
      border-bottom: 1px solid transparent
      color: $default-purple
      display: flex
      font-weight: 600
      height: 40px
      justify-content: space-between
      min-width: 315px
      width: 315px

    .rental-periods
      width: 315px

      .rental-period
        &-head
          align-items: center
          border-bottom: 1px solid $border-element-color
          color: $default-black
          display: flex
          justify-content: space-between
          height: 40px
          min-width: 315px

          .title
            padding-left: 10px
            padding-top: 5px

          .chevron
            padding-right: 10px

        .competitor
          font-size: 0.8em

          &-name,
          &-plan
            padding-right: 10px

          &-name
            color: $default-purple
            cursor: pointer
            height: 88px
            display: flex
            align-items: center
            justify-content: flex-start
            padding-left: $competitor-name-padding-left
            user-select: none
            transition: all 0.1s linear

            &:hover,
            &.active
              background: $default-purple-light

            &.no-plans
              color: $default-gray
              cursor: default

          .no-plans-row,
          &-plan
            background-color: $default-gray-light
            color: $default-gray
            cursor: default
            height: 88px
            display: flex
            align-items: center
            justify-content: flex-start
            padding-left: $competitor-name-padding-left
</style>
